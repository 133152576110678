import React from 'react';
import styled from 'styled-components';
import BetterLink from './betterLink';
import breakpoint from 'styled-components-breakpoint';
import _ from 'lodash';

const MenuItem = styled.div`
    margin: auto;
    padding: 8px 8px;
    &:hover {
        text-shadow: #DDD 1px 1px 1px;
        font-size: 17px;
    }
    transition: 0.2s;
`;

const Wrapper = styled.div`
    display: block;
    overflow: hidden;
    padding: 8px 0;
    box-sizing: border-box;
    width: 180px;
    text-align: center;
    background-color: ${({theme})=>theme.color.secondary};
    color: ${({theme})=>theme.color.text.secondary};
    ${breakpoint('tablet')`
        position: absolute;
        left: 0;
        top: 39px;
    `}
`;

const Underline = styled.div`
    border-bottom: 1px solid black;
    margin: 0 10px;
`;

const Menu = ({values, setVisible})=>{
    values = values.sort((lhs,rhs)=>lhs.node.frontmatter.order - rhs.node.frontmatter.order);
    return(
        <Wrapper>
            {values && values.length > 0 ? values.map(({node},index)=>{
                const { templateKey, title, nameSplitPosition } = node.frontmatter;
                const main = title.slice(0,nameSplitPosition);
                const sub = title.slice(nameSplitPosition);
                return(
                    <BetterLink key={index} to={`/${templateKey}/${_.kebabCase(title)}/`}>
                        <MenuItem onClick={()=>setVisible && setVisible(false)}>
                            {main&&<div>{main}</div>}
                            {sub&&<div>{sub}</div>}
                            <Underline />
                        </MenuItem>
                    </BetterLink>
                )
            }): <div>Empty Menu</div>}
        </Wrapper>
    )
};

export default Menu;


import React from "react"
import styled from 'styled-components';
import Nav from './nav';
import Logo from './logo';
import breakpoint from 'styled-components-breakpoint';

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 1000;
  user-select: none;
  background-color: ${({theme})=>theme.color.primary};
  display: flex;
`;

const TitleWrapper = styled.div`
  
  flex-grow: 1;
  padding: 5px 0 5px 50px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  ${breakpoint('tablet')`
    padding: 5px 0 5px 90px;
  `}
`;

const Title = ()=>(
  <Logo name="logo3" width="170px" height="70px" border="none"/>
)

const Header = () => (
  <StyledHeader>
    <TitleWrapper>
      <Title />
    </TitleWrapper>
    <Nav />
  </StyledHeader>
)

export default Header;

const theme = {
    color: {
        primary : '#2F2E2E',
        secondary: '#b7b7b7',
        card: '#E5E5DE',
        text: {
            primary: '#B2B1B1',
            secondary: '#3f3f3f',
            error: '#943616',
        }
    },
    breakpoints:{
        mobile: 320,
        tablet: 768,
        desktop: 1200
    }
}

export default theme;


//primary : '#2F2E2E',
//primary : '#555555',
import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import HtmlReactParser from 'html-react-parser';
import reactToString from '../helper/reactToString';

//todo check out image to see if there's any bug
// image now is obj when console.log

function SEO({ description, lang, meta, keywords, title, image }) {

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const setting = data.setting.edges[0].node.frontmatter;
        const companyDescription = reactToString(HtmlReactParser(data.companyDescription.edges[0].node.html));
        const metaDescription = 
          description || companyDescription;
        const metaImage = 
          image || `${data.site.siteMetadata.url}${setting.thumbnail.childImageSharp.fluid.src}`;
        const metaKeywords = keywords || setting.keywords;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${setting.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `image`,
                content: metaImage,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `article`,
              },
              {
                name: `twitter:image`,
                content: metaImage,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                metaKeywords.length > 0
                  ? {
                      name: `keywords`,
                      content: metaKeywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        author
        url
      }
    }
    setting: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "setting" } }
    }){
      edges{
        node{
          frontmatter{
            title
            thumbnail{
              childImageSharp{
                fluid(maxWidth: 400){
                  src
                }
              }
            }
          }
        }
      }
    }
    companyDescription: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "companyDescription" } }
    }){
      edges{
        node{
          html
        }
      }
    }
  }
`

import SocialLink from './socialLink';
import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import { graphql, StaticQuery } from 'gatsby';

const query = graphql`
  query {
    setting: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "setting" } }
    }){
      edges{
        node{
          frontmatter{
            instagram
          }
        }
      }
    }
  }
`

const Instagram = ({...props})=> <StaticQuery
    query={query}
    render={data=> {
        const url = data.setting.edges[0].node.frontmatter.instagram;
        return(
            <SocialLink 
                icon={FaInstagram}
                {...props}
                url={url}
            />
        )
    }}
/>

export default Instagram;
import React from 'react';
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components';
import Link from './betterLink';

const Image = styled(Img)`
  width: ${({width})=>width? width : '180px' };
  height: ${({height})=>height? height : '100px' };
  border: ${({border})=> border? border: '1px white solid'};
  background-color: ${({backgroundColor})=> backgroundColor? backgroundColor: 'transparent'};
  border-radius: ${({borderRadius})=> borderRadius? borderRadius: '0'};
`;


const Logo = ({name, width, height, borderRadius, border, backgroundColor}) => (
    <StaticQuery
        query={query}
        render={data =>{
            let fluid;
            switch(name){
              case "logo1":
                fluid = data.logo1.childImageSharp.fluid;
                break;
              case "logo2":
                fluid = data.logo2.childImageSharp.fluid;
                break;
              case "logo3":
                fluid = data.logo3.childImageSharp.fluid;
                break;
              case "title113853":
                fluid = data.title113853.childImageSharp.fluid;
                break;
              default:
                fluid = data.logo1.childImageSharp.fluid;
                break;
            }
            return(
                <Link to="/">
                    <Image width={width} height={height} fluid={fluid} borderRadius={borderRadius} border={border} backgroundColor={backgroundColor}/>
                </Link>
            )
        }}
    />
  )

export default Logo;

export const query = graphql`
  query {
    logo1 : file(relativePath: { eq: "logo1.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logo2 : file(relativePath: { eq: "logo2.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logo3 : file(relativePath: { eq: "logo3.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    title113853 : file(relativePath: { eq: "title113853.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
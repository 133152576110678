import SocialLink from './socialLink';
import React from 'react';
import { FaLine } from 'react-icons/fa';
import { graphql, StaticQuery } from 'gatsby';

const query = graphql`
  query {
    setting: allMarkdownRemark( filter: { 
      frontmatter:{ type:{ eq: "setting" } }
    }){
      edges{
        node{
          frontmatter{
            line
          }
        }
      }
    }
  }
`

const Line = ({...props})=> <StaticQuery
    query={query}
    render={data=> {
        const url = data.setting.edges[0].node.frontmatter.line;
        return(
            <SocialLink 
                icon={FaLine}
                {...props}
                url={url}
            />
        )
    }}
/>

export default Line;